<script>
import WarehousesForm from './WarehousesForm'

export default {
  extends: WarehousesForm,

  methods: {
    onSubmit() {
      this.createWarehouse({ ...this.form, ...this.getCoords })
        .then(() => {
          this.successSubmit({ message: 'Склад успешно создан', routeName: 'warehouses' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>